














































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import dayjs from 'dayjs'

import Account from '@/models/account'

import Listbox from 'primevue/listbox'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Tooltip from 'primevue/tooltip'
import AccountForm from './components/AccountForm.vue'

Vue.component('Listbox', Listbox)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('Tooltip', Tooltip)
Vue.component('AccountForm', AccountForm)

const Auth = namespace('auth')

@Component
export default class Accounts extends Vue {
  @Auth.Getter currentPermissions!: string

  loading = true
  accountParam: (string | null)[] = [null]
  accountToEdit: Account = new Account()
  newMode = false
  enteringEditMode = false

  enableCreate = false
  enableRead = false
  enableUpdate = false

  accounts: (Account|any)[] = []

  showClosedAccounts = false
  accountSidebarItems: Account[] = []

  get filteredAccounts () {
    return this.accountSidebarItems.filter(item => {
      return this.showClosedAccounts || (!this.showClosedAccounts && item.active)
    })
  }

  toggleClosedAccounts () {
    this.showClosedAccounts = !this.showClosedAccounts
  }

  insertActiveExitedSeparator (accounts: Account[]) {
    let idx = -1
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i]
      if (!account.active) {
        idx = i
        break
      }
    }
    if (idx >= 0) {
      accounts.splice(idx, 0, new Account({
        name: 'Closed Accounts',
        active: true
      }))
    }
    return accounts
  }

  get selectedAccounts () {
    const accounts = []
    const relevantAccounts = (this.accountParam && !(this.accountParam.length === 1 && this.accountParam[0] === null))
      ? this.accountParam
      : this.accounts.filter(item => { return item.active }).map(item => item.id).filter(item => { return item !== null })
    for (let i = 0; i < relevantAccounts.length; i++) {
      const account = this.accounts.filter(item => {
        return String(item.id) === String(relevantAccounts[i])
      })[0]
      if (account) {
        accounts.push(account)
      }
    }
    return accounts.sort((a, b) => { return a.displayName.localeCompare(b.displayName) })
  }

  get selectedAccountsWithSummary () {
    const result = this.selectedAccounts
    result.push({
      displayName: 'Total',
      clearedBalance: this.totalClearedBalance,
      workingBalance: this.totalWorkingBalance,
      lastReconciledAt: null
    })
    return result
  }

  get totalClearedBalance () {
    let total = 0.0
    for (let i = 0; i < this.selectedAccounts.length; i++) {
      const account = this.selectedAccounts[i]
      if (account) {
        total += Number(account.clearedBalance)
      }
    }
    return total
  }

  get totalWorkingBalance () {
    let total = 0.0
    for (let i = 0; i < this.selectedAccounts.length; i++) {
      const account = this.selectedAccounts[i]
      if (account) {
        total += Number(account.clearedBalance) + Number(account.pendingBalance)
      }
    }
    return total
  }

  mounted () {
    this.getCurrentPermissions()
    this.getAccounts()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableCreate = permissions.includes('create_account')
    this.enableRead = permissions.includes('read_account')
    this.enableUpdate = permissions.includes('update_account')
  }

  onSaveComplete () {
    this.newMode = false
    this.accountParam = [null]
    this.getAccounts()
  }

  onCancel () {
    this.newMode = false
    this.accountParam = [null]
    this.getAccounts()
  }

  async getAccounts () {
    if (!this.enableRead) return

    this.loading = true

    this.accounts = (await Account
      .includes('defaultInvestment')
      .order({ active: 'desc' })
      .order('name')
      .per(1000)
      .all()
    ).data

    this.accounts = [
      new Account({ id: null, name: 'All Accounts', active: 1 })
    ].concat(this.accounts.map(item => { return item }))

    this.accountSidebarItems = this.insertActiveExitedSeparator(JSON.parse(JSON.stringify(this.accounts)))

    this.loading = false
  }

  onChangeSelectedAccounts (event: any) {
    if (this.enteringEditMode) {
      // skip changing accounts if user clicked edit button
      this.enteringEditMode = false
      return
    }

    this.newMode = false

    const userClickedAllAccounts = event.originalEvent.target.innerText === 'All Accounts'
    if (this.accountParam.includes(null) && this.accountParam.length === 2) {
      if (userClickedAllAccounts) {
        this.accountParam = [null]
      } else {
        this.accountParam.splice(this.accountParam.indexOf(null), 1)
      }
    } else if (this.accountParam.includes(null) || this.accountParam.length === 0) {
      this.accountParam = [null]
    }
  }

  onClickAddNew () {
    this.newMode = true
    this.$set(this, 'accountToEdit', new Account())
    this.$set(this, 'accountParam', [])
  }

  onClickEdit (account: Account) {
    this.enteringEditMode = true
    this.newMode = true
    const accountToEdit = this.accounts.filter(item => {
      return item.id === account.id
    })[0]
    this.$set(this, 'accountToEdit', accountToEdit)
    this.accountParam = [String(account.id)]
  }

  isComplianceViolation (account: Account) {
    if (account.maxDaysWithoutReconcile && account.maxDaysWithoutReconcile > 0) {
      const lastReconciledAt = dayjs(account.lastReconciledAt as any)
      const today = dayjs()
      const daysSinceLastReconciliation = today.diff(lastReconciledAt, 'day')
      if (daysSinceLastReconciliation >= account.maxDaysWithoutReconcile) return true
    }
    return false
  }
}
