














































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

import Account from '@/models/account'
import { Investment } from '@/models/investment'
import AccountTransaction from '@/models/account_transaction'

import AppInputCurrency from '@/components/AppInputCurrency'
import Fieldset from 'primevue/fieldset'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'

Vue.component('AppInputCurrency', AppInputCurrency)
Vue.component('Fieldset', Fieldset)
Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('InputNumber', InputNumber)
Vue.component('Dropdown', Dropdown)

@Component
export default class AccountForm extends Vue {
  @Prop() account!: Account

  editedAccount: Account = new Account()
  investments: Investment[] = []
  processing = false
  errors: string[] = []
  initialTransaction: AccountTransaction = new AccountTransaction({
    accountId: this.account ? this.account.id : null,
    transactionDate: new Date(),
    payeeType: 'Account',
    payeeId: this.account ? this.account.id : null,
    investmentId: this.account ? this.account.defaultInvestmentId : null,
    transactionStatusId: 3,
    draftType: 'initial_adjustment'
  })

  get isNew () {
    return !this.account.id
  }

  get invalid () {
    if (!this.editedAccount.name) return true
    if (this.isNew && !this.initialTransaction.inflow) return true
    return false
  }

  mounted () {
    this.duplicateOriginalAccountForEditing()
    this.getInvestments()
  }

  async getInvestments () {
    this.investments = (await Investment.order('name').per(1000).all()).data
  }

  async save () {
    this.processing = true
    const accountSaveSuccess = await this.editedAccount.save({
      with: [
        'defaultInvestment.id'
      ]
    })

    if (!accountSaveSuccess) {
      this.processing = false
      this.errors = [String(this.editedAccount.errors)]
      return
    }

    if (this.isNew) {
      this.initialTransaction.accountId = this.editedAccount.id || ''
      this.initialTransaction.investmentId = this.editedAccount.defaultInvestmentId
      this.initialTransaction.payeeId = this.editedAccount.id || ''
      const initialTransactionSaveSuccess = await this.initialTransaction.save()
      if (!initialTransactionSaveSuccess) {
        this.processing = false
        this.errors = [String(this.initialTransaction.errors)]
        return
      }
    }

    this.saveComplete(this.editedAccount)
    this.processing = false
  }

  duplicateOriginalAccountForEditing () {
    this.editedAccount = this.account ? new Account(this.account) : new Account()
    if (this.account.id !== undefined) {
      this.editedAccount.isPersisted = true
    }
  }

  clearErrors () {
    this.errors = []
  }

  @Emit()
  cancel (account: Account = this.account) {
    return account
  }

  @Emit()
  saveComplete (account: Account) {
    return account
  }
}
